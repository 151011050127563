/* #root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
} */

.logo {
  height: 6em;
  padding: 1.2em;
  /* will-change: filter; */
  /* transition: filter 300ms; */
}
.logo:hover {
  filter: drop-shadow(0 0 2em #676463);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #676463);
}

.login-div input{
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* @keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 1em;
}

.read-the-docs {
  color: #888;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination li {
  line-height: 10px;
  padding: 2px 10px;
}

.pagination li.active {
  background-color: #e8eaf4;
  border-radius: 5px;
  color: #3f52a3 !important;
}

.pagination li.active a {
  font-weight: 600;
  color: #3f52a3 !important;
  text-shadow: none !important;
  text-decoration: none !important;
  position: relative;
  top: 6px;
}

.pagination li:hover a {
  font-weight: normal;
  color: #5d6166 !important;
  position: relative;
  top: 6px;
}

.pagination li a {
  color: #5d6166;
  font-size: 14px;
  position: relative;
  top: 6px;
  cursor: pointer;
}

.pagination li a .action-arrow-icon {
  font-size: 23px;
  position: relative;
}

.pagination li.previous,
.pagination li.next {
  background-color: transparent;
}

.pagination li.previous::before,
.pagination li.next::before {
  background-color: transparent !important;
  border: none !important;
}

.pagination li.previous a,
.pagination li.next a {
  position: relative;
  top: 0;
}

.pagination li.previous.disabled,
.pagination li.next.disabled {
  opacity: 0.5;
}

.login-div {
  height: 100vh;
}

.login-bg {
  height: 100%;
  object-fit: cover;
}

.error {
  color: red;
  font-size: 14px; /* Adjust the size if needed */
  font-weight: 500 ; /* Optional: makes the error stand out more */
}
