/* Layout.css */
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header { 
  width: 100%;
  background-color: #f4f4f4; /* Optional: Set background color */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
}

.main {
  display: flex;
  flex-grow: 1;
  background-color: #f7f7f9;
}

.sidebar {
  width: 250px; /* Adjust width as needed */
  background-color: #f4f4f4; /* Optional: Set background color */
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
}

.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Optional: Add scroll for overflow content */
}
