.layout {
  display: flex;
}
.layout.sm .leftside-bar {
  width: 60px;
}
.layout.sm .leftside-bar ul li.dashboard {
  margin-bottom: 8px;
}
.layout.sm .leftside-bar ul li.dashboard p {
  display: none;
}
.layout.sm .leftside-bar ul li a span {
  margin-right: 0;
  font-size: 24px;
  margin-left: 3px;
}
.layout.sm .leftside-bar ul li a label {
  display: none;
}
.layout.sm .leftside-bar .sidebar-logo img {
  width: 30px;
  position: relative;
  left: -12px;
}
.layout.sm .container-box {
  width: calc(100% - 60px);
  margin-left: 60px;
}
.layout.sm .container-box footer {
  width: calc(100% - 60px);
}
.layout.sm .container-box .menu-btn img {
  transition: 0.5s;
  transform: rotate(180deg);
}
.leftside-bar {
  transition: 0.5s;
  width: 150px;
  background-color: #eff2f7;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  height: 100vh;
  font-family: "Poppins", sans-serif;
  position: fixed;
}
.leftside-bar .sidebar-logo {
  padding: 14px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-bottom: 10px;
}
.leftside-bar ul {
  margin: 0;
  padding: 0 0 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.leftside-bar ul li {
  list-style: none;
  padding: 0 8px;
  margin-bottom: 8px;
}
.leftside-bar ul li p {
  display: none;
}
.leftside-bar ul li.dashboard {
  position: relative;
  margin-bottom: 38px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.leftside-bar ul li.dashboard p {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  padding: 12px 8px;
  position: absolute;
  bottom: -40px;
  opacity: 0.7;
}
.leftside-bar ul li a {
  display: flex;
  padding: 12px 8px;
  border-radius: 8px;
  color: #000;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
}
.leftside-bar ul li a .material-icons {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.leftside-bar ul li a label {
  margin-bottom: 0;
  margin-left: 10px;
}
.leftside-bar ul li a:hover {
  background-color: #fff;
  color: #3f52a3;
}
.leftside-bar ul li a.active {
  background-color: #fff;
  color: #3f52a3;
}
.container-box {
  width: calc(100% - 150px);
  margin-left: 150px;
  padding: 0;
  transition: 0.5s;
}
.container-box header {
  padding: 5px 10px;
  border-bottom: 1px solid #e5e5e5;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}
.container-box header .menu-btn {
  position: absolute;
  left: -16px;
}
.container-box header > div {
  text-align: end;
  display: flex;
  justify-content: end;
}
.container-box header .iconBtn {
  background-color: transparent;
  height: 32px;
  padding: 0;
  margin-right: 24px;
  position: relative;
}
.container-box header .profile-icons .dropdown-toggle {
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 50%;
  background-color: #3f52a3;
  border-color: #3f52a3;
}
.container-box header .profile-icons .dropdown-toggle::after {
  display: none;
}
.container-box header .profile-icons .dropdown-menu {
  padding: 0;
  border: 1px solid rgb(0 0 0 / 5%);
}
.container-box header .profile-icons .dropdown-item {
  font-size: 14px;
  position: relative;
  padding: 12px 16px;
}
.container-box header .profile-icons .dropdown-item:first-child {
  border-bottom: 1px solid rgb(0 0 0 / 5%);
}
.container-box header .profile-icons .dropdown-item span {
  font-size: 20px;
  position: absolute;
  right: 8px;
  display: none;
}
.container-box header .notification-icons .dropdown-toggle {
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  position: relative;
}
.container-box header .notification-icons .dropdown-toggle::after {
  display: none;
}
.container-box header .notification-icons .dropdown-toggle span {
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.518px;
  background-color: #f78f1e;
  position: absolute;
  border-radius: 4px;
  line-height: 16px;
  top: 0;
  right: 0px;
  padding: 0 4px;
}
.container-box header .notification-icons .dropdown-menu {
  width: 316px;
  padding: 0;
}
.container-box header .notification-icons .dropdown-menu .dropdown-header {
  padding: 12px 16px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}
.container-box header .notification-icons .dropdown-menu .dropdown-header label {
  color: #f78f1e;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 2px;
}
.container-box header .notification-icons .dropdown-menu .notification-list {
  border: 1px solid #e5e5e5;
  max-height: 190px;
  overflow-y: auto;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner.non-reader {
  background-color: #f78f1e0f;
  position: relative;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner:last-child {
  border-bottom: none;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .circle-txt {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  background-color: #3f52a329;
  color: #3f52a3;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details {
  margin-left: 16px;
  width: calc(100% - 40px);
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details p span {
  font-weight: 700;
  font-size: 14px;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details p span.accepted {
  color: #09ae2d;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details p span.reject {
  color: #c62026;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details p span.provided {
  color: #2c3972;
}
.container-box header .notification-icons .dropdown-menu .notification-list .notification-list-inner .notification-details span {
  font-size: 12px;
  color: #5d6166;
}
.container-box header .notification-icons .dropdown-menu .footer {
  padding: 12px 16px;
  border-top: 1px solid #e5e5e5;
}
.container-box header .notification-icons .dropdown-menu .footer button {
  width: 100%;
  padding: 8px 16px;
  height: 34px;
  line-height: 18px;
}
.container-box .containerInner {
  padding: 24px;
}
.container-box footer {
  padding: 16px 24px;
  border-top: 1px solid #e5e5e5;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 220px);
  background-color: #fff;
  z-index: 10;
}
.container-box footer p {
  margin: 0;
  font-size: 12px;
  color: #5d6166;
}
.container-box footer p img {
  width: 43.5px;
}
.container-box footer label {
  margin: 0;
  font-size: 12px;
}
.dev-body .demo {
  display: none;
}
.logo-header {
  background-color: #3f52a3;
  height: 50px;
  width: 219px;
  transition: 0.6s;
  border-bottom: 1px solid #ffffff38;
  padding: 12px 16px;
  position: relative;
  display: block !important;
}
.logo-header h1 {
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  margin: 0;
}
.logo-header button {
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 12px;
}
.logo-header button i {
  font-size: 22px;
  color: #ffffff;
}
.content-lg {
  padding: 24px;
  background-color: #fff;
  width: calc(100% - 220px);
  margin-left: 220px;
  padding-bottom: 60px;
}
.sideBar-logo {
  display: none;
}
.wms-header-hamburger {
  display: none;
}
.lg .wms-header-container {
  width: 100%;
  margin-left: 0px;
}
.lg .bloomsbury-logo {
  background-color: #3f52a3;
  width: 220px;
  transition: 0.6s;
}
.lg .bloomsbury-logo svg:last-child {
  margin-left: 8px;
  transition: 1s;
}
.lg .w-100 span {
  right: 0 !important;
}
.lg .w-100 span .bx {
  margin-left: 0;
}
.sm .wms-header-container {
  margin-left: 0;
  width: 100%;
}
.sm .wms-header-container .logo-header {
  transition: 0.6s;
  width: 70px;
}
.sm .wms-header-container .logo-header h1 {
  display: none;
}
.sm footer {
  width: calc(100% - 70px);
}
.sm .menu-btn img {
  transition: 0.5s;
  transform: rotate(180deg);
}
.sm .bloomsbury-logo {
  background-color: #3f52a3;
  width: 70px;
  transition: 0.6s;
  text-align: center;
}
.sm .bloomsbury-logo svg:last-child {
  display: none;
}
.side-menu {
  background: #3f52a3;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  top: 50px;
}
.side-menu.sm .sideBar-logo {
  display: none;
}
.side-menu.sm .bottom-logo {
  display: none;
}
.side-menu.lg {
  width: 220px !important;
}
.side-menu.lg .bottom-logo {
  display: none;
}
.side-menu.lg .bloomsbury-logo svg:last-child {
  margin-left: 8px;
  transition: 0.8s;
}
.bloomsbury-logo {
  background-color: #3f52a3;
  width: 220px;
  transition: 0.6s;
}
.bloomsbury-logo svg:last-child {
  margin-left: 8px;
  transition: 1s;
}
.w-100 span {
  right: 0 !important;
}
.w-100 span .bx {
  margin-left: 0;
}
.demo-body .no-demo {
  display: none;
}
.demo-body.sm .leftside-bar .sidebar-logo {
  padding: 13px 10px;
}
.demo-body .leftside-bar .sidebar-logo {
  padding: 9px;
  text-align: center;
}
.demo-body .leftside-bar .sidebar-logo img {
  padding: 5px;
  border-radius: 8px;
  background-color: #fff;
  width: 86px;
  height: 38px;
}
.demo-body .leftside-bar .sidebar-logo .text-logo {
  color: #f78f1e;
  font-size: 18px;
  padding: 2px;
  border-radius: 8px;
  background-color: #fff;
  width: 40px;
  font-weight: 700;
}
.no-focus-outline:focus {
  outline: none;
}
.navbar-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
  color: #000;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 0.375rem;
}

.navbar-item:hover, .navbar-item:visited, .navbar-item:focus-visible {
  background-color: #f1f1f1; 
  border: #f1f1f1;
  color: #121212; 
}

.dark .navbar-item:hover {
  background-color: #797979; 
  color: #fff; 
}
/* layout.css */

.header-cls {
  /* width: 1440px; */
  height: 88px;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally if needed */
}

.max-w-screen-xl {
  max-width: 100%;
  width: 100%;
  /* max-width: 1440px; Ensure this matches the header width */
}

.flex-center {
  display: flex;
  align-items: center;
}

.no-focus-outline:focus {
  outline: none;
}

.space-x-3 > :not(:last-child) {
  margin-right: 0.75rem;
}

.space-x-3.rtl\:space-x-reverse > :not(:last-child) {
  margin-right: 0;
  margin-left: 0.75rem;
}
